import { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";

import { useQuery, useMutation, useReactiveVar } from "@apollo/client";

import { Responsive, WidthProvider } from "react-grid-layout";

import Loading from "components/Loading";

import BottomButtons from "./BottomButtons";
import GridItem from "./GridItem";
import DashboardHeader from "./DashboardHeader";

import { entriesCacheVar, entriesByIdVar, snapshotEntriesByIdVar } from "apollo-config/local-state/entries";
import { flightpathAccountsByIdVar, snapshotAccountsByIdVar } from "apollo-config/local-state/accounts";
import { DASHBOARD_QUERY, DASHBOARD_LAYOUT_MUTATION } from "./graphql";

import { AppContext } from "../../../AppContext";
import { buildOrderedDashboard } from "./utils";
import { createEntriesCache } from "shared/utilities/entry-utilities";

import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import styles from "./Grid.module.scss";

const Grid = () => {
  const context = useContext(AppContext);
  const params = useParams();
  const navigate = useNavigate();

  const GridLayout = useMemo(() => WidthProvider(Responsive), []);

  const dashboardId = useMemo(() =>
    Object.hasOwn(params, "dashboard")
      ? params.dashboard.split("-")[0]
      : "1",
    [params]
  );

  const [displayItems, setDisplayItems] = useState(true);
  const [editedItem, setEditedItem] = useState(null);
  const [type, setType] = useState(null);

  // Used to trigger a re-render once snapshot accounts & entries have been loaded.
  const [_loadedSnapshotData, setLoadedSnapshotData] = useState(false);

  const [updateDashboardLayout] = useMutation(DASHBOARD_LAYOUT_MUTATION);
  const dashboardQuery = useQuery(DASHBOARD_QUERY, {
    variables: {
      id: params.dashboard?.split("-")[0] || "1"
    }
  });

  useEffect(() => {
    const dashboard = dashboardQuery.data?.dashboard;
    if (dashboard) {
      addSnapshotAccountsAndEntriesToCache(dashboard);
      setLoadedSnapshotData(true);
      context.setChildrenLoading(false);
    }
  }, [dashboardQuery]);

  const onEdit = (type, item) => () => {
    setEditedItem(item);
    setType(type);
    navigate(`/dashboard/${params.dashboard}/${type}s/${item.id}/update`);

    if (type === "table") {
      setTimeout(() => {
        setDisplayItems(false);
      }, 300); // 300ms is Blueprint animation duration
    }
  }

  const onCreate = (type) => () => {
    setType(type);
    navigate(`/dashboard/${params.dashboard}/${type}s/create`);
  }

  const onModalClose = () => {
    setDisplayItems(true);
    setEditedItem(null);
    setType(null);
    navigate(`/dashboard/${params.dashboard}`);
  }

  const onLayoutChange = (newLayout) => {
    const layout = [];

    const { layout: previousLayout } = dashboardQuery.data?.dashboard;

    for (const item of newLayout) {
      const { x, y, h, w, i } = item;
      const [id, type] = i.split("-");

      layout.push({
        id,
        type,
        x,
        y,
        w,
        h,
      });
    }

    let layoutsAreTheSame = true;
    if (previousLayout) {
      for (let i = 0; i < layout.length; i++) {
        const item = layout[i];
        const prevItem = previousLayout[i];
        if (
          !previousLayout[i] ||
          item.x !== prevItem.x ||
          item.y !== prevItem.y ||
          item.h !== prevItem.h ||
          item.w !== prevItem.w ||
          item.type !== prevItem.type ||
          item.id !== prevItem.id
        ) {
          layoutsAreTheSame = false;
          break;
        }
      }
    }

    if (previousLayout && !layoutsAreTheSame) {
      updateDashboardLayout({
        variables: {
          dashboard: {
            id: dashboardId,
            layout,
          },
        },
        update: (proxy) => {
          const queryData = proxy.readQuery({ query: DASHBOARD_QUERY, variables: { id: dashboardId } });
          const data = {
            ...queryData,
            dashboard: {
              ...queryData.dashboard,
              layout,
            },
          };
          proxy.writeQuery({ query: DASHBOARD_QUERY, variables: { id: dashboardId }, data });
        },
      });
    }
  };

  // const { width, ref: resizeRef } = useResizeDetector({ handleHeight: false });

  const { forecastStartDate, user } = context;
  const userCanEdit = user?.tenant && (user.role === "ADMIN" || user.role === "SUPER" || user.role === "EDIT");

  let layout, orderedDashboard;
  if (dashboardQuery.data?.dashboard) {
    const result = buildOrderedDashboard(dashboardQuery.data.dashboard);
    layout = result.layout;
    orderedDashboard = result.orderedDashboard;
  }

  const gridWrapperClasses = [styles.gridWrapper];
  // if (width < 1000) gridWrapperClasses.push(styles.showScrollbar);

  return (
    <div className={gridWrapperClasses.join(" ")}>
      <DashboardHeader />
      {dashboardQuery.loading || !dashboardQuery.data?.dashboard ? (
        <Loading />
      ) : (
        <>
          {(!orderedDashboard.length) ? (
            <h3 className={styles.emptyDashboard}>Your dashboard is empty. Add some charts and tables to make it interesting!</h3>
          ) : (displayItems) ? (
            <GridLayout
              cols={{ xxs: 2 }}
              breakpoints={{ xxs: 0 }}
              layouts={{ xxs: layout }}

              draggableHandle={`.${styles.dragHandle}`}
              isDraggable={userCanEdit}
              isResizable={userCanEdit}
              onDragStop={onLayoutChange}
              onResizeStop={onLayoutChange}
              rowHeight={50}
            >
              {orderedDashboard.map((item, i) => (
                <div
                  className={styles.gridItemWrapper}
                  key={`${item.id}-${item.entityType}`}
                >
                  <GridItem
                    customColors={dashboardQuery.data.dashboard.colors}
                    dashboardId={dashboardId}
                    item={item}
                    key={`${item.id}-${item.entityType}`}
                    mounted={true}
                    onEdit={onEdit}
                  />
                </div>
              ))}
            </GridLayout>
          ) : null}

          {(userCanEdit) ? (
            <BottomButtons
              onCreateChart={onCreate("chart")}
              onCreateTable={onCreate("table")}
            />
          ) : null}

          <Outlet context={{
            dashboardId,
            forecastStartDate,
            onClose: onModalClose,
            editedTable: type === "table" ? editedItem : null,
            editedChart: type === "chart" ? editedItem : null,
          }} />
        </>
      )}
    </div>
  );

};

export default Grid;

function addSnapshotAccountsAndEntriesToCache(dashboard) {
  console.time("Add snapshot accounts and entries to cache");

  const entries = [];
  const entriesById = {};
  const accountsById = {};

  // Gather all the accounts and entries sent with the charts
  for (const chart of dashboard.charts) {
    if (!chart.lines) continue;
    for (const line of chart.lines) {
      if (line.account && !Object.hasOwn(accountsById, line.account.id)) {
        accountsById[line.account.id] = line.account;
        if (line.entries) {
          entries.push(...line.entries.filter((entry) => !entriesById[entry.id]));
          for (const entry of line.entries) {
            entriesById[entry.id] = entry;
          }
        }
      }
    }
  }

  // Gather all the accounts and entries sent with the tables
  for (const table of dashboard.tables) {
    for (const account of table.accounts) {
      if (!Object.hasOwn(accountsById, account.id)) {
        accountsById[account.id] = account;
        if (table.entries?.[account.id]) {
          entries.push(...table.entries[account.id].filter((entry) => !entriesById[entry.id]));
          for (const entry of table.entries[account.id]) {
            entriesById[entry.id] = entry;
          }
        }
      }
    }
  }

  // Add entries to the cache
  entriesCacheVar({
    ...entriesCacheVar(),
    ...createEntriesCache(entries).entriesCache,
  });
  entriesByIdVar({
    ...entriesByIdVar(),
    ...entriesById,
  });
  snapshotEntriesByIdVar({
    ...snapshotEntriesByIdVar(),
    ...entriesById,
  });

  // Add accounts to the cache
  flightpathAccountsByIdVar({
    ...flightpathAccountsByIdVar(),
    ...accountsById,
  });
  snapshotAccountsByIdVar({
    ...snapshotAccountsByIdVar(),
    ...accountsById,
  });

  console.timeEnd("Add snapshot accounts and entries to cache");
}
