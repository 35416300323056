import { useState, useEffect, useContext } from "react";

import { Outlet, useParams, Navigate } from "react-router-dom";

import { useQuery } from '@apollo/client';

import slug from "slugify";

import RenderError from "components/RenderError";

import { createYearsArrays } from "../company-settings/snapshots/forecast-settings-utilities";
import Onboarding from "./onboarding";

import { DASHBOARDS_QUERY } from "./grid/graphql";
import { AppContext } from "../../AppContext";

const Dashboard = (props) => {
  const dashboardsQuery = useQuery(DASHBOARDS_QUERY);
  const context = useContext(AppContext);
  const params = useParams();

  if (!context.initialLoadDone && context.setupComplete) context.setChildrenLoading(props.dashboardsQuery.loading);

  const { setupComplete, forecastStartDateSet } = context;

  if (dashboardsQuery.loading) return null;

  if (dashboardsQuery.error) {
    return <RenderError
      message={`Chances are we are working on fixing this, but if it keeps happening, please don't hesitate to <a href="mailto:support@flightpathfinance.com">contact support</a>.`}
      title="There was an error trying to display your dashboard"
    />;
  }

  if (!setupComplete) {
    return <Onboarding
      forecastStartDateSet={forecastStartDateSet}
      setupComplete={setupComplete}
    />;
  }

  if (!Object.hasOwn(params, "dashboard")) {
    const { id: defaultId, name: defaultName } = dashboardsQuery.data.dashboards[0];
    const defaultPath = `/dashboard/${defaultId}-${slug(defaultName.toLowerCase())}`;

    return <Navigate to={defaultPath} />;
  }

  return <Outlet />;
};

export default Dashboard;
