import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Intent, Position, ProgressBar, OverlayToaster, Toast2 } from "@blueprintjs/core";

export default function AppToaster({ toasts }) {
  return (
    <OverlayToaster position={Position.TOP_RIGHT}>
      {Object.keys(toasts).map((name) => (
        <Toast2
          icon={toasts[name].icon}
          key={name}
          message={(
            <div>
              <span>{toasts[name].message}</span>
              <ProgressBar
                className={classNames("docs-toast-progress", { "bp5-no-stripes": toasts[name].progress >= 100 })}
                intent={toasts[name].progress < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                value={toasts[name].progress / 100}
              />
            </div>
          )}
        />
      ))}
    </OverlayToaster>
  );
}

AppToaster.propTypes = {
  toasts: PropTypes.object.isRequired,
};
