import React, { useContext, useState } from "react";
import slug from "slugify";

import { useNavigate, useParams, NavLink } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Icon, Menu, MenuDivider, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import TypedConfirmation from "components/TypedConfirmation";

import EditDashboardTab from "./EditDashboardTab";

import { DASHBOARDS_QUERY, REMOVE_DASHBOARD } from "../graphql";

import { AppContext } from "../../../../AppContext";

import styles from "./styles.module.scss";

export default function DashboardHeader() {
  const { data, loading } = useQuery(DASHBOARDS_QUERY);
  const [removeDashboard] = useMutation(REMOVE_DASHBOARD);

  const [editedDashboard, setEditedDashboard] = useState(null);
  const [overlayOnTop, setOverlayOnTop] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const { user } = useContext(AppContext);

  const userCanEdit = user?.tenant && ["EDIT", "ADMIN", "SUPER"].includes(user.role);

  const navigate = useNavigate();
  const params = useParams();

  const isEditing = !!editedDashboard;

  if (loading) return null;

  const selectedDashboard = data.dashboards.find((dashboard) => dashboard.id === (params?.dashboard ? params.dashboard.split("-")[0] : 1));

  // Add the new dashboard to the array if it exists
  const dashboards = editedDashboard?.id === 0 ? [...data.dashboards, editedDashboard] : data.dashboards;

  const switchDashboard = (dashboard) => {
    navigate(`/dashboard/${dashboard.id}-${slug(dashboard.name.toLowerCase())}`);
  };

  const editDashboard = (add) => () => {
    setOverlayOnTop(true);
    setEditedDashboard(add ? { id: 0, name: "New dashboard" } : selectedDashboard);
  };

  const onRemoveConfirm = () => {
    navigate(`/dashboard/${dashboards[0].id}-${slug(dashboards[0].name.toLowerCase())}`);
    setShowRemoveConfirmation(false);
    removeDashboard({
      variables: { id: selectedDashboard.id },
      optimisticResponse: {
        removeDashboard: true,
      },
      update: (proxy) => {
        const queryData = proxy.readQuery({ query: DASHBOARDS_QUERY });
        const data = {
          ...queryData,
          dashboards: [...queryData.dashboards],
        };
        data.dashboards.splice(dashboards.findIndex((dashboard) => dashboard.id === selectedDashboard.id), 1);
        proxy.writeQuery({ query: DASHBOARDS_QUERY, data });
      },
    });
  };

  const closeEditMode = () => {
    setEditedDashboard(null);
    setTimeout(() => setOverlayOnTop(false), 150);
  };

  return (
    <div className={styles.dashboardHeader}>
      <div
        className={[
          styles.overlay,
          isEditing ? styles.visible : undefined,
          overlayOnTop ? styles.overlayOnTop : undefined,
        ].join(" ")}
      />
      <ul className={styles.tabsList}>
        {dashboards.map((dashboard) => (
          <li
            key={dashboard.id}
            onClick={() => !isEditing ? switchDashboard(dashboard) : null}
          >
            {editedDashboard?.id === dashboard.id || !dashboard.id ? (
              <EditDashboardTab closeEditMode={closeEditMode} dashboard={dashboard} />
            ) : (
              <NavLink className={({ isActive }) => isActive ? styles.active : null} to={`/dashboard/${dashboard.id}-${slug(dashboard.name.toLowerCase())}`}>{dashboard.name}</NavLink>
            )}
          </li>
        ))}
      </ul>
      <div className={styles.rightMenuWrapper}>
        {userCanEdit ? (
          <Popover
            autoFocus={false}
            content={(
              <Menu>
                <MenuItem onClick={editDashboard(true)} text="Add a new dashboard" />
                <MenuDivider />
                <MenuItem onClick={editDashboard(false)} text="Rename current dashboard" />
                <MenuItem onClick={() => setShowRemoveConfirmation(true)} text="Delete current dashboard" />
              </Menu>
            )}
            lazy
            minimal
            position={Position.BOTTOM_RIGHT}
            usePortal={false}
          >
            <button className={styles.menuIcon}>
              <Icon icon={IconNames.MORE} iconSize={24} />
            </button>
          </Popover>
        ) : null}
      </div>
      <TypedConfirmation
        isOpen={showRemoveConfirmation}
        onCancel={() => setShowRemoveConfirmation(false)}
        onConfirm={onRemoveConfirm}
      >
        <p>Are you sure that you want to remove this dashboard and all its charts and tables?</p>
        <p>
          Please type 'delete' in the input field then click <strong>Delete</strong> below if you are sure
          that you would like this dashboard to be removed.
        </p>
      </TypedConfirmation>
    </div>
  );
}
