import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import { withPrivatePrivileges, withAdminPrivileges, withSupportPrivileges } from "./privilege-hooks";

import Admin from "./views/admin/Admin";
import Dashboard from "./views/dashboard/Dashboard";
import ChartCreationModal from "./views/dashboard/chart-builder/ChartCreationModal";
import TableBuilder from "./views/dashboard/table-builder";

import OperatingModel from "./views/operating-model";
import CompanySettings from "./views/company-settings/CompanySettings";
import UserSettings from "./views/user-settings";
import RevenueModel from "./views/revenue-model";
import UserNotFound from "./views/UserNotFound";
import NoMatch from "./views/NoMatch";
import QuickBooksDisconnected from "./views/QuickBooksDisconnected";
import CustomGoogleSheet from "./views/google-sheets";

import Worksheets from "./views/worksheets";
import Worksheet from "./views/worksheets/Worksheet";
import WorksheetList from "./views/worksheets/List";

import CompanySettingsCustomization from "./views/company-settings/customization";
import CompanySettingsUsers from "./views/company-settings/users";
import CompanySettingsIntegrations from "./views/company-settings/integrations";
import CompanySettingsChartOfAccounts from "./views/company-settings/chart-of-accounts";
import CompanySettingsScenarios from "./views/company-settings/scenarios";
import CompanySettingsEmployees from "./views/company-settings/payroll/CompanySettingsEmployees";
import CompanySettingsSnapshots from "./views/company-settings/snapshots";
import CompanySettingsForecastStartDate from "./views/company-settings/forecast-start-date";
import CompanySettingsAppSumo from "./views/company-settings/AppSumo";

import RevenueModelChartOfPlans from "./views/revenue-model/chart-of-plans";
import RevenueModelSettings from "./views/revenue-model/revenue-settings";
import RevenueModelForecast from "./views/revenue-model/forecast";

import DashboardGrid from "./views/dashboard/grid";

import App from "./App";

export default function() {
  return createBrowserRouter([
    {
      path: "/",
      Component: App,
      children: [
        {
          path: "admin",
          Component: withSupportPrivileges(Admin),
        },
        {
          path: "",
          Component: withPrivatePrivileges(Dashboard),
        },
        {
          path: "operating-model/:statement",
          Component: withPrivatePrivileges(OperatingModel),
        },
        {
          path: "dashboard",
          Component: withPrivatePrivileges(Dashboard),
          children: [
            {
              path: ":dashboard",
              Component: DashboardGrid,
              children: [
                {
                  path: "charts/create",
                  Component: ChartCreationModal,
                },
                {
                  path: "charts/:id/update",
                  Component: ChartCreationModal,
                },
                {
                  path: "tables/create",
                  Component: TableBuilder,
                },
                {
                  path: "tables/:id/update",
                  Component: TableBuilder,
                },
              ]
            },
          ],
        },
        {
          path: "welcome",
          Component: withPrivatePrivileges(Dashboard),
        },
        {
          path: "worksheets",
          Component: withPrivatePrivileges(Worksheets),
          children: [
            {
              path: "",
              Component: WorksheetList
            },
            {
              path: "new",
              Component: Worksheet,
            },
            {
              path: ":slug/edit",
              Component: Worksheet,
            }
          ],
        },
        {
          path: "revenue-model",
          Component: withPrivatePrivileges(RevenueModel, { requiredTier: 2 }),
          children: [
            {
              path: "",
              element: <Navigate to="/revenue-model/summary" />,
            },
            {
              path: "chart-of-plans",
              Component: RevenueModelChartOfPlans,
            },
            {
              path: "settings",
              Component: RevenueModelSettings,
            },
            {
              path: ":view",
              Component: RevenueModelForecast,
            },
          ],
        },
        {
          path: "user-settings",
          Component: withPrivatePrivileges(UserSettings),
        },
        {
          path: "google-sheets/:index(\d+)",
          Component: withPrivatePrivileges(CustomGoogleSheet),
        },
        {
          path: "unauthorized",
          Component: UserNotFound,
        },
        {
          path: "quickbooks-disconnected",
          Component: QuickBooksDisconnected,
        },
        {
          path: "company",
          Component: withAdminPrivileges(CompanySettings),
          children: [
            {
              path: "",
              element: <Navigate to="/company/customization" />,
            },
            {
              path: "users",
              Component: CompanySettingsUsers,
            },
            {
              path: "customization",
              Component: CompanySettingsCustomization,
            },
            {
              path: "integrations",
              Component: CompanySettingsIntegrations,
            },
            {
              path: "accounts",
              Component: CompanySettingsChartOfAccounts,
            },
            {
              path: "scenarios",
              Component: withAdminPrivileges(CompanySettingsScenarios, { requiredTier: 2 }),
            },
            {
              path: "snapshots",
              Component: CompanySettingsSnapshots,
            },
            {
              path: "forecast-start-date",
              Component: CompanySettingsForecastStartDate,
            },
            {
              path: "hiring-plan",
              Component: withAdminPrivileges(CompanySettingsEmployees, { requiredTier: 2 }),
            },
            {
              path: "appsumo",
              Component: withAdminPrivileges(CompanySettingsAppSumo),
            },
          ]
        },
        {
          path: "*",
          Component: NoMatch,
        },
      ],
    },
  ]);
}
