import {sortBy} from "lodash";

/*let prev = null;
let prevDashboard = null;*/
export const buildOrderedDashboard = (dashboard, limit) => {
  //console.log("Computing dashboard from scratch");
  /*console.log("==========");
  console.log(JSON.stringify(prev));
  console.log(JSON.stringify(dashboard.layout));
  console.log("Layout?", prev === dashboard.layout);
  console.log("Dashboard?", prevDashboard === dashboard);
  console.log("==========");
  prev = dashboard.layout;
  prevDashboard = dashboard;*/
  const orderedDashboard = [];
  const layout = [];
  let x = 0;
  let y = 0;
  let totalColsUsed = 0;
  let i = 0;
  const sortedLayout = sortBy(dashboard.layout, ["y", "x"]);
  for(const item of sortedLayout) {
    const matchingEntity = dashboard[`${item.type}s`].find((entity) => entity.id === item.id);
    if(matchingEntity) {
      const newObj = {...matchingEntity};
      newObj.entityType = item.type;
      orderedDashboard.push(newObj);
      totalColsUsed++;
      layout.push({
        i: `${newObj.id}-${newObj.entityType}`,
        x: typeof item.x === "undefined" ? x : item.x,
        y: typeof item.y === "undefined" ? y : item.y,
        w: typeof item.w === "undefined" ? 1 : item.w,
        h: typeof item.h === "undefined" ? 10 : item.h,
        minH: item.type === "chart" ? 10 : 5,
        maxH: item.type === "chart" ? 10 : 200,
      });

      x = x === 1 ? 0 : 1;

      if(totalColsUsed && totalColsUsed % 2 === 0) y++;
    }
    i++;
    if(limit && i === limit) break;
  }

  return {orderedDashboard, layout};
};
